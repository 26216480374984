import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

const IndexPage = () => (
  <Layout>
    <Seo title="Our Services" />

    <div className="masthead mb-5">
      <Container className="singleCol text-center">
        <h1>Our Services</h1>
      </Container>
    </div>
    
    <Container>
      <Row className="g-5">
        <Col lg={6}>
          <Link to="/services/children/">
            <div className="card">
              <StaticImage
                src="../images/kid-with-blue-headphones-on-laptop.jpg"
                loading="eager"
                quality={95}
                aspectRatio={3 / 2}
                className="card-img-top"
                formats={["auto", "webp", "avif"]}
                alt="Young child sitting in front of a laptop computer"
              /> 
              <div class="card-body">
                <h2 className="card-title text-center">Children</h2>
              </div>
            </div>
          </Link>
        </Col>

        <Col lg={6}>
          <Link to="/services/adults/">
            <div className="card">
              <StaticImage
                src="../images/adults-around-a-board-room-table-no-crop.jpg"
                loading="eager"
                quality={95}
                aspectRatio={3 / 2}
                className="card-img-top"
                formats={["auto", "webp", "avif"]}
                alt="Adult business man"
              /> 
              <div class="card-body">
                <h2 className="card-title text-center">Adults</h2>
              </div>
            </div>
          </Link>
        </Col>

        
      </Row>
    </Container>
    
  </Layout>
)

export default IndexPage
